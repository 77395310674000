<template>
  <DialogModal
    :is-visible="isVisible"
    :title="
      $t('subscriptions.confirm_replace_with_single_purchase_modal.title')
    "
    :message="
      $t('subscriptions.confirm_replace_with_single_purchase_modal.message')
    "
    :confirm-button-text="
      $t('subscriptions.confirm_replace_with_single_purchase_modal.confirm')
    "
    :cancel-button-text="
      $t('subscriptions.confirm_replace_with_single_purchase_modal.cancel')
    "
    @confirm="removeSubscriptionAndEmitConfirm"
    @cancel="emit('update:is-visible', false)"
  />
</template>

<script setup lang="ts">
const props = defineProps({
  isVisible: {
    type: Boolean,
    default: false,
  },
  productId: {
    type: Number,
    default: undefined,
  },
  variantId: {
    type: Number,
    default: undefined,
  },
});

const emit = defineEmits(['confirm', 'update:is-visible']);

const subscriptionStore = useSubscriptionStore();

const removeSubscriptionAndEmitConfirm = async () => {
  if (!props.productId || !props.variantId) {
    return;
  }

  const cartItemAlreadyAddedAsSubscription =
    await subscriptionStore.getSubscriptionProductInCart(
      props.productId,
      props.variantId
    );

  if (cartItemAlreadyAddedAsSubscription) {
    subscriptionStore.removeSubscriptionProduct(
      cartItemAlreadyAddedAsSubscription.productId,
      cartItemAlreadyAddedAsSubscription.variantId,
      cartItemAlreadyAddedAsSubscription.frequencyInDays
    );
  }

  emit('confirm', {
    productId: props.productId,
    variantId: props.variantId,
  });

  emit('update:is-visible', false);
};
</script>
