import { defineStore } from 'pinia';
import { safeJsonStringify } from '#root/shared/utils/types/string-utils';
import { setCookie } from '../utils/cookie-utils';

export interface FavoriteStoreState {
  favorites: number[];
}

const updateFavorites = (locale: LocaleTerritory, favorites: number[]) => {
  setCookie(
    `favorites_${locale}`,
    safeJsonStringify(favorites) || '',
    60 * 60 * 24 * 365
  );
};

export const useFavoriteStore = defineStore('favorites', {
  state: (): FavoriteStoreState => ({
    favorites: [],
  }),
  actions: {
    setFavoritesFromCookie(locale: LocaleTerritory) {
      const favorites = useCookie(`favorites_${locale}`).value || [];

      this.favorites = favorites;
    },
    addFavorite(productId: number) {
      const nuxtApp = useNuxtApp();
      const locale = nuxtApp.$i18n.locale.value as LocaleTerritory;

      this.favorites.push(productId);

      updateFavorites(locale, this.favorites);
    },

    removeFavorite(productId: number) {
      const nuxtApp = useNuxtApp();
      const locale = nuxtApp.$i18n.locale.value as LocaleTerritory;

      const index = this.favorites.indexOf(productId);
      this.favorites.splice(index, 1);

      updateFavorites(locale, this.favorites);
    },
  },
});
