<template>
  <img class="absolute right-2 top-2 z-10 size-14" :src="listBadgeSrc" />
</template>

<script setup lang="ts">
import ListBadgeChicken from '~/assets/images/product/list-label-chicken.svg?url';
import ListBadgeFish from '~/assets/images/product/list-label-fish.svg?url';

const props = defineProps({
  listBadge: {
    type: String,
    default: '',
  },
});

const listBadgeImages = {
  highProteinChicken: ListBadgeChicken,
  singleProteinFish: ListBadgeFish,
};

const listBadgeSrc = computed(
  () => listBadgeImages[props.listBadge as keyof typeof listBadgeImages]
);
</script>
