<template>
  <Button class="w-full" :color="color" :link="loginUrl">
    {{ $t(label) }}
    <DynamicPictogram name="bank-id" class="ml-2 inline-block" />
  </Button>
</template>
<script setup lang="ts">
const localePath = useLocalePath();

const props = defineProps({
  redirectPage: {
    type: String,
    default: 'shop-dashboard',
  },
  label: {
    type: String,
    default: 'account.login_page.bank_id_login',
  },
  color: {
    type: String,
    default: 'primary',
  },
});

const { getLoginUrl } = useAuthStore();
const loginUrl = computed(() => {
  const redirectAfterLoginUrl = localePath({ name: props.redirectPage });
  return getLoginUrl({
    redirectPath: `${redirectAfterLoginUrl}?redirectLoggedIn=true&date=${Date.now()}`,
  });
});
</script>
