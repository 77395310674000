<template>
  <div class="relative flex flex-row flex-wrap items-center whitespace-nowrap">
    <template v-if="enableVariantSelect">
      <Button
        class="w-full"
        size="sm"
        text-size="lg"
        :color="buttonColor"
        @click="toggleVariantSelect = !toggleVariantSelect"
      >
        <transition name="fade" mode="out-in">
          <span
            v-if="showAddedToCart"
            key="add-to-cart-loading"
            class="flex items-center whitespace-nowrap"
          >
            <span
              class="invisible w-0 text-base leading-[27px] sm:visible sm:w-auto"
              >{{ $t('cart.added') }}</span
            >
            <DynamicPictogram name="check-mark" class="sm:ml-2" size="md" />
          </span>
          <div v-else class="dropdown flex w-full items-center">
            <span class="dropdown ml-auto">
              {{ $formatPrice(specialPrice || regularPrice) }}
            </span>
            <DynamicPictogram name="caret" class="ml-auto" size="sm" />
          </div>
        </transition>
      </Button>
      <div
        v-click-outside="vcoConfig"
        class="absolute w-full overflow-hidden rounded-lg bg-white shadow-lg"
        style="top: -15px"
        :class="toggleVariantSelect ? 'z-20' : 'invisible'"
      >
        <ul>
          <li
            v-for="variant in variants"
            :key="variant.id"
            class="border-b p-2 px-4 hover:bg-black/6"
            :class="variant.is_available ? 'cursor-pointer' : 'opacity-50'"
            @click="selectVariant(variant.id)"
          >
            <div class="flex justify-between py-2">
              <div>
                <span
                  >{{ variant.label }}
                  <span v-if="variant.second_label">
                    - {{ variant.second_label }}</span
                  ></span
                >
              </div>
              <div class="flex items-center">
                <span
                  v-if="variant.sale_price"
                  class="font-semibold text-primary-default"
                >
                  {{ $formatPrice(variant.sale_price) }}
                </span>
                <span
                  class="mr-2 font-semibold"
                  :class="
                    variant.sale_price
                      ? 'ml-1 text-sm line-through'
                      : 'text-primary-default'
                  "
                >
                  {{ $formatPrice(variant.base_price) }}
                </span>
                <CartPictogram
                  key="add-to-cart"
                  :cart-amount="qtyInCart"
                  class="ml-auto"
                  filled-class="text-black/54"
                  empty-class="text-black/54"
                  cart-amount-class="text-primary-default text-xs"
                />
              </div>
            </div>
          </li>
        </ul>
      </div>
    </template>
    <template v-else>
      <span
        v-if="specialPrice"
        class="mr-4 whitespace-nowrap text-black/54 line-through"
      >
        {{ $formatPrice(regularPrice) }}
      </span>
      <Button
        v-if="isBuyButtonLinkToProduct"
        class="flex flex-1"
        color="secondary"
        :link="link"
        :disabled="disabled"
        size="sm"
        text-size="lg"
        @click="buyButtonClicked('click-link')"
      >
        <div class="ml-auto">
          <span
            v-if="showFromPrice"
            :class="`pr-1 text-base ${props.fromTextStyle}`"
          >
            {{ $t('from').toLowerCase() }}
          </span>
          <span
            :class="`${props.fromAmountStyle}`"
            data-test-id="productCardPrice"
          >
            {{ $formatPrice(specialPrice || regularPrice) }}
          </span>
        </div>
        <DynamicPictogram
          name="chevron"
          class="ml-auto rotate-180 text-black/54"
          size="lg"
        />
      </Button>
      <Button
        v-else
        class="flex flex-1"
        size="sm"
        text-size="lg"
        :disabled="disabled"
        data-test-id="buy-button"
        :color="buttonColor"
        @click="buyButtonClicked('click-button')"
      >
        <transition name="fade" mode="out-in">
          <span
            v-if="showAddedToCart"
            key="add-to-cart-loading"
            class="flex items-center whitespace-nowrap"
          >
            <span
              class="invisible w-0 text-base leading-[27px] sm:visible sm:w-auto"
              >{{ $t('cart.added') }}</span
            >
            <DynamicPictogram name="check-mark" class="sm:ml-2" size="md" />
          </span>
          <div v-else class="flex w-full items-center">
            <span class="ml-auto" data-test-id="product-card-price">
              {{ $formatPrice(specialPrice || regularPrice) }}
            </span>
            <CartPictogram
              key="add-to-cart"
              :cart-amount="qtyInCart"
              class="ml-auto"
              filled-class="text-white"
              empty-class="text-white"
              cart-amount-class="text-primary-default text-xs"
            />
          </div>
        </transition>
      </Button>
    </template>
  </div>
</template>
<script setup lang="ts">
const props = defineProps({
  disabled: {
    type: Boolean,
    default: false,
  },
  isBuyButtonLinkToProduct: {
    type: Boolean,
    default: false,
  },
  link: {
    type: String,
    default: '',
  },
  specialPrice: {
    type: [String, Number],
    default: 0,
  },
  regularPrice: {
    type: [String, Number],
    default: 0,
  },
  showFromPrice: {
    type: Boolean,
    default: false,
  },
  qtyInCart: {
    type: Number,
    default: 0,
  },
  showAddedToCart: {
    type: Boolean,
    default: false,
  },
  variants: {
    type: Array,
    default: () => [],
  },
  enableVariantSelect: {
    type: Boolean,
    default: false,
  },
  fromTextStyle: {
    type: String,
    default: 'text-black/54',
  },
  fromAmountStyle: {
    type: String,
    default: 'text-primary-default',
  },
});

const { enabledTheme } = useRuntimeConfig().public;

const toggleVariantSelect = ref(false);

const handler = () => {
  toggleVariantSelect.value = false;
};
// Note: The middleware will be executed if the event was fired outside the element.
//       It should have only sync functionality and it should return a boolean to
//       define if the handler should be fire or not
const middleware = (event) =>
  typeof event.target?.className === 'string' &&
  !event.target.className.includes('dropdown');
const vcoConfig = {
  handler,
  middleware,
  events: ['dblclick', 'click'],
  // Note: The default value is true, but in case you want to activate / deactivate
  //       this directive dynamically use this attribute.
  isActive: true,
  // // Note: The default value is false. Sets the capture option for EventTarget addEventListener method.
  // //       Could be useful if some event's handler calls stopPropagation method preventing event bubbling.
  capture: false,
};

const emit = defineEmits(['click-button', 'click-link']);

const buttonColor = computed(() => enabledTheme || 'primary');

const selectVariant = (variantId) => {
  toggleVariantSelect.value = false;
  emit('click-button', variantId);
};

const buyButtonClicked = (event) => {
  emit(event);
};
</script>
