<template>
  <div
    v-if="amount"
    class="pointer-events-none absolute z-10"
    :class="{ 'md:-left-2 md:-top-2': shouldOverflow }"
  >
    <CampaignBadge
      :campaign-amount="campaignAmount"
      :size="size"
      :color-class="colorClass"
      text-class="md:ml-1"
      :show-campaign-text="showCampaignText"
    />
  </div>
</template>

<script setup lang="ts">
import { DISCOUNT_TYPES } from '#root/shared/config/discount-config';

const { $formatPrice } = useNuxtApp();

type Size = 'sm' | 'md' | 'lg';

const props = defineProps({
  amount: {
    type: Number,
    default: 0,
  },
  type: {
    type: String,
    default: DISCOUNT_TYPES.per_item,
  },
  size: {
    type: String as PropType<Size>,
    default: 'md',
  },
  colorClass: {
    type: String,
    default: 'text-supporting-dark-3',
  },
  shouldOverflow: {
    type: Boolean,
    default: false,
  },
  showCampaignText: {
    type: Boolean,
    default: false,
  },
});

const campaignAmount = computed(() => {
  const { amount, type } = props;
  return type === DISCOUNT_TYPES.percentage
    ? `${amount}%`
    : `-${$formatPrice(amount)}`;
});
</script>
