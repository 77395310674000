<template>
  <ProductCardBuyButtonBase v-bind="($attrs, $props)"
    ><slot
  /></ProductCardBuyButtonBase>
</template>

<script lang="ts">
import props from '~/components/domain/product-card/ProductCardBuyButtonBase.vue';

export default {
  props,
};
</script>
